import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { ProjectedInfluencer } from "@trainwell/features";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectBrand } from "src/slices/brandSlice";
import CheckMarkPurple from "/assets/splash/check_mark_purple.svg";
import TestimonialProofPurple from "/assets/splash/testimonial_proof_purple.svg";
import TestimonialProofWhite from "/assets/splash/testimonial_proof_white.svg";
type SplashPageTwoPanelProps = {
  titleCopy: string;
  bodyCopy: string;
  ctaCopy: string;
  influencer?: ProjectedInfluencer | undefined;
  onClick: () => void;
};

export default function SplashPage_TwoPanel_Loved({
  titleCopy,
  bodyCopy,
  ctaCopy,
  influencer,
  onClick = () => {},
}: SplashPageTwoPanelProps) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));
  const brand = useAppSelector(selectBrand);

  return (
    <div style={{ margin: 0, padding: 0, height: "100%" }}>
      {isPhone && (
        <div
          style={{
            position: "absolute",
            background: "#FFFFFF",
            top: 0,
            paddingTop: "12px",
            height: "70px",
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {brand && (
            <>
              <img src={brand.logo_url} alt="partner-logo" height={37} />
              <Box
                sx={{
                  height: 37,
                  width: "1px",
                  mx: "8px",
                  background: "#443564",
                }}
              />
            </>
          )}
          <a href={"https://www.trainwell.net/" + location.search}>
            <img
              style={{ marginTop: "-3px" }}
              src="/assets/trainwell-wordmark.svg"
              alt="logo"
              width={125}
              height={37}
            />
          </a>
        </div>
      )}
      <div
        style={{
          marginTop: isPhone ? "-60px" : undefined,
          position: isPhone ? "relative" : "absolute",
          display: "grid",
          gap: isPhone ? "48px" : undefined,
          gridTemplateColumns: isPhone ? "100%" : "50% 50%",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "#FFF",
        }}
      >
        <div
          style={{
            order: isPhone ? 1 : 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            justifyContent: "center",
          }}
        >
          {!isPhone && (
            <Box
              sx={{
                paddingTop: "10px",
                top: "16px",
                left: "32px",
                width: "250px",
                position: "absolute",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {brand && (
                <>
                  <img src={brand.logo_url} alt="partner-logo" height={37} />
                  <Box
                    sx={{
                      height: 37,
                      minWidth: "1px",
                      mx: "8px",
                      background: "#443564",
                    }}
                  />
                </>
              )}
              <a href={"https://www.trainwell.net/" + location.search}>
                <img
                  style={{ marginTop: "-3px" }}
                  src="/assets/trainwell-wordmark.svg"
                  alt="logo"
                  width={125}
                  height={37}
                />
              </a>
            </Box>
          )}
          <div
            style={{
              maxWidth: isPhone ? "90%" : "75%",
              textAlign: isPhone ? "center" : "left",
            }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: "32px",
                justifyContent: isPhone ? "center" : "start",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "8px",
                  fontWeight: 500,
                  fontSize: 15,
                  color: "#443564",
                  background: "#C8E3F9",
                  borderRadius: "16px",
                  paddingLeft: "16px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  paddingRight: "16px",
                }}
              >
                {!influencer && (
                  <FavoriteIcon
                    fontSize="small"
                    style={{
                      color: "#443564",
                    }}
                  />
                )}
                LOVED BY 39,000+ CLIENTS!
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: isPhone ? "center" : "start",
                marginBottom: "30px",
              }}
            >
              {influencer && (
                <img
                  src={CheckMarkPurple}
                  style={{ height: "32px", width: "32px" }}
                />
              )}

              <Typography
                // @ts-expect-error
                variant="YTBig"
                sx={{
                  fontSize: isPhone ? 22 : 32,
                  fontWeight: 900,
                  lineHeight: 1,
                  color: "#443564",
                }}
              >
                {brand?.splash_title ? brand.splash_title : titleCopy}
              </Typography>
            </div>

            <Typography
              variant="body2"
              sx={{
                fontSize: isPhone ? 18 : 22,
                maxWidth: isPhone ? "100%" : "75%",
                marginTop: "16px",
              }}
            >
              {brand?.splash_body ? brand.splash_body : bodyCopy}
            </Typography>
            <Button
              style={{
                marginTop: isPhone ? "2em" : "3em",
                marginBottom: isPhone ? "4em" : "2em",
                height: "55px",
                width: "100%",
                borderRadius: "8px",
              }}
              onClick={onClick}
            >
              {brand?.splash_cta ? brand.splash_cta : ctaCopy}
            </Button>
          </div>
        </div>
        <div
          style={{
            order: isPhone ? 0 : 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: isPhone ? "250px" : undefined,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {isPhone ? (
              <div
                style={{
                  backgroundImage: influencer
                    ? undefined
                    : brand?.splash_image
                      ? `url(${brand.splash_image})`
                      : `url(assets/splash/woman_gym.jpg)`,
                  backgroundSize: influencer ? undefined : "cover",
                  backgroundPosition: influencer ? undefined : "center",
                  width: "100%",
                  height: "250px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {influencer && (
                  <img
                    src={influencer.assets.splash_asset}
                    style={{
                      marginTop: "16px",
                      maxWidth: "60%",
                      maxHeight: "65%",
                    }}
                  />
                )}
                <div
                  style={{
                    bottom: "-8px",
                    left: "auto",
                    right: "auto",
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={
                      influencer
                        ? TestimonialProofPurple
                        : TestimonialProofWhite
                    }
                    style={{
                      width: "35%",
                      minWidth: "250px",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  position: "relative",
                  backgroundImage: influencer
                    ? undefined
                    : brand?.splash_image
                      ? `url(${brand.splash_image})`
                      : `url(assets/splash/woman_gym.jpg)`,
                  backgroundSize: influencer ? undefined : "cover",
                  backgroundPosition: influencer ? undefined : "center",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {influencer && (
                  <img
                    src={influencer.assets.splash_asset}
                    style={{ width: "60%" }}
                  />
                )}
                <img
                  src={
                    influencer ? TestimonialProofPurple : TestimonialProofWhite
                  }
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    left: "32px",
                    width: "35%",
                    minWidth: "250px",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
