import type { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { CoachMatch } from "@trainwell/api-sdk";
import type { Trainer } from "@trainwell/types";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "./store";

export const getPotentialCoaches = createAsyncThunk(
  "coach/getPotentialCoaches",
  async ({ count }: { count: number }, { getState }) => {
    const state = getState() as RootState;
    console.log(
      `Getting potential coaches: tagsGoal: ${state.survey.tagGoals}`,
    );

    const response = await api.trainers.getPersonalizedMatches(
      count,
      {
        userId: state.client.clientID,
        defaultTimezoneOffset: new Date().getTimezoneOffset() * -60,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        gender: state.survey.gender === "Man" ? "male" : "female",
        age: state.survey.age,
        height: state.survey.height,
        weight: state.survey.weight,
        tagsGoals: state.survey.tagGoals,
        tagsCommunicationStyle: state.survey.tagCommunicationStyle,
        tagsExperience: state.survey.tagExperience,
        tagsIdentity: state.survey.tagDemographics,
        tagsSpecialties: state.survey.tagSpecializations,
        tagsObWindowsPreferred: state.survey.tagObWindowsPreferred,
      },
      state.coach.forcedTrainerId,
    );

    if (!response)
      throw new Error(
        "Endpoint did not returned any data when a response was expected",
      );

    return response;
  },
);

export const getOneCoach = createAsyncThunk(
  "coach/getOneCoach",
  async ({ trainerId }: { trainerId: string }) => {
    const response = await api.trainers.getOne(trainerId, true);

    if (!response)
      throw new Error(
        "Endpoint did not returned any data when a response was expected",
      );

    return response;
  },
);

export const selectTrainer = createAsyncThunk(
  "coach/selectTrainer",
  async (trainer: CoachMatch, { getState }) => {
    const state = getState() as RootState;
    const response = await api.clients.updateTrainerId(
      state.client.clientID ?? "",
      trainer.trainer_id,
    );
    return response;
  },
);

// Define a type for the slice state
interface SelectCoachState {
  recommendedCoaches: CoachMatch[];
  forcedTrainer: Trainer | undefined;
  coachJustification: string | undefined;
  coachJustificationBullets: { title: string; body: string }[] | undefined;
  selectedAvailability: { trainerId: string; availability: string };
  availabilityPagination: number;
  selectedTrainer: CoachMatch | undefined;
  potentialCoachesStatus: "idle" | "loading" | "succeeded" | "failed";
  getOneCoachStatus: "idle" | "loading" | "succeeded" | "failed";
  didLoadMoreCoaches: boolean;
  forcedTrainerId: string | undefined;
  errors: {
    getPotentialCoachesError: SerializedError | undefined;
    getOneCoachError: SerializedError | undefined;
    selectTrainerError: SerializedError | undefined;
  };
}

// Define the initial state using that type
const initialState: SelectCoachState = {
  selectedTrainer: undefined,
  forcedTrainer: undefined,
  coachJustification: undefined,
  coachJustificationBullets: undefined,
  selectedAvailability: { trainerId: "", availability: "" },
  availabilityPagination: 1,
  recommendedCoaches: [],
  potentialCoachesStatus: "idle",
  getOneCoachStatus: "idle",
  didLoadMoreCoaches: false,
  forcedTrainerId: undefined,
  errors: {
    getPotentialCoachesError: undefined,
    getOneCoachError: undefined,
    selectTrainerError: undefined,
  },
};

export const coachSlice = createSlice({
  name: "coach",
  initialState,
  reducers: {
    resetSelectCoach: () => initialState,
    getAllPotentialCoaches: (state) => {
      state.didLoadMoreCoaches = true;
    },
    setForceTrainerId: (state, action: PayloadAction<string | undefined>) => {
      const newValue = action.payload;
      state.forcedTrainerId = newValue;

      if (newValue === undefined) {
        state.forcedTrainer = undefined;
      }
    },
    resetSelectedTrainer: (state) => {
      state.selectedTrainer = undefined;
    },
    setSelectedAvailability: (
      state,
      action: PayloadAction<SelectCoachState["selectedAvailability"]>,
    ) => {
      state.selectedAvailability = action.payload;
    },
    resetSelectedAvailability: (state) => {
      state.selectedAvailability = { trainerId: "", availability: "" };
    },
    setAvailabilityPagination: (state, action: PayloadAction<number>) => {
      state.availabilityPagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPotentialCoaches.pending, (state) => {
      state.potentialCoachesStatus = "loading";
      state.recommendedCoaches = [];
      state.selectedTrainer = undefined;
      state.selectedAvailability = { trainerId: "", availability: "" };
    });
    builder.addCase(getPotentialCoaches.fulfilled, (state, action) => {
      console.log("Redux: Got potential coaches");
      console.log("ACTION PAYLOAD:", action.payload);
      state.coachJustification = action.payload.justification;
      state.coachJustificationBullets = action.payload.justification_bullets;
      state.recommendedCoaches = action.payload.coach_weights
        .sort((a, b) => (a.score < b.score ? 1 : -1))
        .map(({ trainer }) => {
          return trainer;
        });
      state.potentialCoachesStatus = "succeeded";
      state.selectedTrainer = undefined;
      state.selectedAvailability = { trainerId: "", availability: "" };
    });
    builder.addCase(getPotentialCoaches.rejected, (state, action) => {
      state.potentialCoachesStatus = "failed";
      state.errors.getPotentialCoachesError = action.error;
    });
    builder.addCase(getOneCoach.pending, (state) => {
      state.getOneCoachStatus = "loading";
      state.recommendedCoaches = [];
      state.selectedTrainer = undefined;
      state.selectedAvailability = { trainerId: "", availability: "" };
    });
    builder.addCase(getOneCoach.fulfilled, (state, action) => {
      state.recommendedCoaches = [action.payload];
      state.forcedTrainer = action.payload;
      state.getOneCoachStatus = "succeeded";
      state.selectedTrainer = undefined;
      state.selectedAvailability = { trainerId: "", availability: "" };
    });
    builder.addCase(getOneCoach.rejected, (state, action) => {
      state.getOneCoachStatus = "failed";
      state.errors.getOneCoachError = action.error;
    });
    builder.addCase(selectTrainer.fulfilled, (state, action) => {
      console.log("Redux: Set trainer info");
      const trainer = action.meta.arg;

      state.selectedTrainer = trainer;
    });
    builder.addCase(selectTrainer.rejected, (state, action) => {
      state.errors.selectTrainerError = action.error;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetSelectCoach,
  getAllPotentialCoaches,
  setForceTrainerId,
  setSelectedAvailability,
  resetSelectedTrainer,
  setAvailabilityPagination,
  resetSelectedAvailability,
} = coachSlice.actions;

export default coachSlice.reducer;

export const selectTrainerName = (state: RootState) => {
  if (!state.coach.selectedTrainer) return undefined;
  return state.coach.selectedTrainer.full_name;
};

export const selectTrainerFirstName = (state: RootState) => {
  if (!state.coach.selectedTrainer) return undefined;
  return state.coach.selectedTrainer.first_name;
};

export const selectPotentialCoachStatus = (state: RootState) => {
  return state.coach.potentialCoachesStatus;
};
export const selectSelectedAvailability = (state: RootState) => {
  return state.coach.selectedAvailability;
};
export const selectAvailabilityPagination = (state: RootState) => {
  return state.coach.availabilityPagination;
};
